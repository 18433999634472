import React, { useCallback, useEffect, useState } from "react";
import { getData, postData } from "../axios/apiHelper";
import SlideToggle from "react-slide-toggle";
import { ENDPOINT } from "../axios/apiConfig";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import DisplayText from "./CommonComponent/DisplayText";
import { Button } from "react-bootstrap";
import { LazyLoadComponent } from "react-lazy-load-image-component";
import useUserTracking from "../hooks/useUserTracking";
let path_image = process.env.REACT_APP_ONESOURCE;
const Highlight = ({ firstExpand, setExpand, moveToHighchart, highchartClick, scrollToSection }) => {
  const [box, setBoxData] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isHighlightNotLoaded, setIsHighlightNotLoaded] = useState(true);
  const [expendStatus, setExpendStatus] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [expandWidth, setExpandWidth] = useState(0);
  const [highLightSectionTrack, setHighLightSectionTrack] = useState(0);
  const trackUserAction = useUserTracking();
  useEffect(() => {
    const fetchArticleData = async () => {
      try {
        // //loader("show");
        const post = await getData(`${process.env.REACT_APP_API_URL_LUMEN}${ENDPOINT.POSTS}`);
        setBoxData(post?.data?.data || []);
      } catch (err) {
        console.error("-err", err);
      } finally {
        // //loader("hide");
        setIsHighlightNotLoaded(false);
      }
    };

    fetchArticleData();
  }, []);

  useEffect(() => {
    if (expendStatus || box.length === 0) return;

    const timer = setTimeout(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % box.length);
    }, 5000);

    return () => clearTimeout(timer);
  }, [currentIndex, box.length, expendStatus]);

  const updateTracking = useCallback(async () => {
    if (highLightSectionTrack === 0) return;

    try {
      await postData(`${ENDPOINT.UPDATETRACKING}`, { id: highLightSectionTrack });
    } catch (err) {
      console.error(err);
    } finally {
      setHighLightSectionTrack(0);
    }
  }, [highLightSectionTrack]);

  const userTracking = useCallback(async (action, id, title) => {
    await updateTracking();
    try {
      // const { data } = await postData(`${ENDPOINT.TRACKING}`, { article_id: id, action: "Highlights" });
      const { data } = await trackUserAction(id, 'Highlights', `${title}`);
      setHighLightSectionTrack(data?.id || 0);
    } catch (err) {
      console.error(err);
    }
  }, [updateTracking]);
  return (
    <>
      <SlideToggle
        duration={1000}
        collapsed={true}
        whenReversedUseBackwardEase={false}
        render={({ toggle, setCollapsibleElement }) => (
          <div className="highlight-box">
            <div className="highlight-title">
              <p>Highlights</p>
              {firstExpand?.expand && firstExpand?.flag == 1 ? (
                <img
                  src={path_image + "close-icon.png"}
                  alt=""

                  onClick={() => {
                    if (isOpen) {
                      setIsOpen(false);
                      toggle();
                      setTimeout(() => {
                        setExpand({
                          ...firstExpand,
                          expand: false,
                          flag: 0,
                          close: 0,
                        });
                        setExpandWidth(0);
                        setExpendStatus(false);
                        updateTracking();
                      }, 1000);
                    }
                  }}
                />
              ) : (
                ""
              )}
            </div>
            <div className={`highlight-content ${firstExpand?.flag == 1
                ? "expand"
                : firstExpand?.close == 0
                  ? "close"
                  : ""
                }`}
            >
              <div
                className="article-main-content"
                style={{ width: expandWidth ? expandWidth : "" }}
              >
                {isHighlightNotLoaded ? (
                  <div className="article-main-img">
                    {" "}
                    <SkeletonTheme color="#5e6c77" highlightColor="#a9b7c1">
                      {" "}
                      <Skeleton duration={2} height={410} width={297} />{" "}
                    </SkeletonTheme>
                  </div>
                ) : (
                  <div className="article-main-img">
                    {firstExpand?.flag == 1 ? (
                      <img src={firstExpand?.image} alt="" />
                    ) : (
                      <>
                        {box?.length > 0 ? (
                          <img
                            className="first_img_load"
                            onClick={() => {
                              moveToHighchart();
                              setExpand({
                                expand: true,
                                image: box?.[currentIndex]?.image,
                                flag: 1,
                                article: box?.[currentIndex]?.article,
                                heading: box?.[currentIndex]?.heading,
                                subheading: box?.[currentIndex]?.subheading,
                                video: box?.[currentIndex]?.video,
                                description: box?.[currentIndex]?.description,
                              });
                              userTracking(
                                "highlights",
                                box?.[currentIndex]?.id,
                                box?.[currentIndex]?.heading,
                              );
                            }}
                            src={box?.[currentIndex]?.image}
                            loading="lazy" // New line for lazy loading
                            alt=""
                          />
                        ) : null}
                      </>
                    )}
                  </div>
                )}

                <div className="article-description">
                  <div className="article-category">
                    {firstExpand?.article ? firstExpand?.article : "Article"}
                  </div>
                  <div className="article-detail-title">
                    <h5>{firstExpand?.heading}</h5>
                    <h6 className="expand-subheading">
                      {firstExpand?.subheading}
                    </h6>
                  </div>
                </div>
              </div>
              <div className="article-des-full" ref={setCollapsibleElement} style={{display:firstExpand?.expand==true && firstExpand?.flag==1 ?"block":"none"}}>
                <div className="article-des-full-detailed">
                  <h6>
                    {firstExpand?.description ? (
                      <DisplayText
                        text={firstExpand?.description}
                        highchartClick={highchartClick}
                      />
                    ) : null}
                  </h6>
                </div>
                <div className="article-list">
                  {isHighlightNotLoaded ? (
                    <SkeletonTheme color="#5e6c77" highlightColor="#a9b7c1">
                      {" "}
                      {[...Array(3)].map((_, index) => (
                        <div className={`article-list-box  `} key={index}>
                          <div className="article-img">
                            <Skeleton width={86} height={115} />
                          </div>
                          <div
                            className="article-description"
                            style={{ marginLeft: 10 }}
                          >

                            <div className="article-detail-title">
                              <h6>
                                <Skeleton width={1000} height={35} />
                              </h6>
                              <p>
                                <Skeleton width={970} height={27} />
                              </p>
                            </div>
                          </div>
                        </div>
                      ))}
                    </SkeletonTheme>
                  ) : (
                    box?.map((item, index) => (
                      <div
                        className={`article-list-box ${index == currentIndex ? "active" : ""
                          }`}
                        key={index}
                        onClick={() => {
                          setExpand({
                            expand: true,
                            image: item?.image,
                            flag: 1,
                            article: item?.article,
                            heading: item?.heading,
                            subheading: item?.subheading,
                            video: item?.video,
                            description: item?.description,
                          });
                          if (!isOpen) {
                            setIsOpen(true);

                            toggle();
                          }
                          userTracking("highlights", item?.id, item?.heading,);
                          setCurrentIndex(index);
                          setExpendStatus(true);
                          // moveToHighchart()
                        }}
                      >
                        <div className="article-img ">
                          <img src={item?.image} alt="" />
                        </div>
                        <div className="article-description">
                          <div className="article-category">
                            {item?.article ? item?.article : "Article"}
                          </div>
                          <div className="article-detail-title">
                            <h6>{item?.heading}</h6>
                            <p>{item?.subheading}</p>
                          </div>
                        </div>
                      </div>
                    ))
                  )}
                </div>
              </div>
              <div className="article-list">
                {isHighlightNotLoaded ? (
                  <SkeletonTheme color="#5e6c77" highlightColor="#a9b7c1">
                    {" "}
                    {[...Array(3)].map((_, index) => (
                      <div className={`article-list-box  `} key={index}>
                        <div className="article-img">
                          <Skeleton width={100} height={115} />
                        </div>
                        <div
                          className="article-description"
                          style={{ marginLeft: 10 }}
                        >
                          <div className="article-detail-title">
                            <h6>
                              <Skeleton width={1000} height={35} />
                            </h6>
                            <p>
                              <Skeleton width={970} height={27} />
                            </p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </SkeletonTheme>
                ) : (
                  box?.map((item, index) => (
                    <div
                      className={`article-list-box ${index == currentIndex ? "active" : ""
                        }`}
                      key={index}
                      onClick={() => {
                        setExpand({
                          expand: true,
                          image: item?.image,
                          flag: 1,
                          article: item?.article,
                          heading: item?.heading,
                          subheading: item?.subheading,
                          video: item?.video,
                          description: item?.description,
                        });
                        if (!isOpen) {
                          setIsOpen(true);

                          toggle();
                        }
                        userTracking("highlights", item?.id, item?.heading);
                        setCurrentIndex(index);
                        setExpendStatus(true);
                        // moveToHighchart()
                      }}
                    >
                      <LazyLoadComponent>

                        <div className="article-img ">
                          <img src={item?.image} alt="" />
                        </div>
                        <div className="article-description">
                          <div className="article-category">
                            {item?.article ? item?.article : "Article"}
                          </div>
                          <div className="article-detail-title">
                            <h6>{item?.heading}</h6>
                            <p>{item?.subheading}</p>
                          </div>
                        </div>
                      </LazyLoadComponent>
                    </div>
                  ))
                )}
              </div>
              {firstExpand?.flag == 1 ? (
                <Button
                  className="close"
                  onClick={() => {
                    setExpendStatus(false);
                    scrollToSection("highchart_move");
                    updateTracking();
                    // moveToHighchart()
                    if (isOpen) {
                      setIsOpen(false);
                      toggle();
                    }
                    setExpand({
                      ...firstExpand,
                      expand: false,
                      flag: 0,
                      close: 0,
                    });
                  }}
                >
                  Close
                </Button>
              ) : (
                ""
              )}
            </div>
          </div>
        )}
      />
    </>
  );
};
export default React.memo(Highlight);
