import React, { useEffect, useRef } from "react";
import { ENDPOINT } from "../axios/apiConfig";
import { loader } from "./CommonComponent/Loader";
import {postData} from "../axios/apiHelper";
// import queryString from 'query-string';
import { useNavigate } from "react-router-dom";

import { db, auth } from "../config/firebaseConfig";
import { doc, setDoc } from 'firebase/firestore';
import { createUserWithEmailAndPassword, signInWithEmailAndPassword } from 'firebase/auth';
import eventConfig from "../config/eventconfig.json";


const Redirect =()=>{
    const navigate = useNavigate();
    // const queryParams = queryString.parse(window.location.search);
    const queryString = window.location.search;
    const queryParams = new URLSearchParams(queryString);
    const queryData=useRef({})
    useEffect(() => {
      let query=queryParams.get('user-id').split("goto");
      let codeQuery=queryParams.get('user-id').split(/code/);
      if(query?.length==2){
        queryData.current={
          userId:query[0],
          goto:query[1]
        };

      }else if(codeQuery?.length==2){
        queryData.current={
          userId:codeQuery[0],
          code:codeQuery[1]
        };
      }else{
        queryData.current={
          userId:queryParams.get('user-id')
        };
      }
         myFun();
         login()
    },[]);

 const login = async() =>{
     let body = {
       user_id:  queryData?.current?.userId
     };
     try{
       const isLogin = await postData(ENDPOINT.NEWLOGIN, body);

      let firebaseId = isLogin?.data?.data?.firebase_id;
      if(!firebaseId || firebaseId == '' || firebaseId == null){
        const firebaseId = await checkAndCreateUser(isLogin?.data?.data?.username);
        localStorage.setItem("fireud",firebaseId);

        const storeFireBase = await postData(`${ENDPOINT?.STORE_FIREBASE}`, {
          user_id:isLogin?.data?.data?.user_id,
          firebase_id:firebaseId
        }); 
      }else{
        localStorage.setItem("fireud",firebaseId);
      }

      if (Object.keys(isLogin?.data?.data?.registered_event).length === 0) {
        localStorage.setItem("register",0);
      } else {
        // if(isLogin?.data?.event_id !=undefined &&isLogin?.data?.event_id!=0){
          let checkEventExist = Object.hasOwn(isLogin?.data?.data?.registered_event, isLogin?.data?.event_id  )
          // let checkEventExist = Object.hasOwn(isLogin?.data?.data?.registered_event,eventConfig?.eventId)
          let exist = checkEventExist ? 1 : 0;
          localStorage.setItem("register",exist);
        // }else{
        //   localStorage.setItem("register",0);
        // }
      }

       localStorage.setItem("evd",isLogin?.data?.event_id?isLogin?.data?.event_id:eventConfig?.eventId); 
       localStorage.setItem("eventCode",isLogin?.data?.event_code?isLogin?.data?.event_code:eventConfig?.eventCode);  
       localStorage.setItem("dhdjdluytt",isLogin?.data?.token);
       localStorage.setItem("bhdkdlolepk","pastrara6789943dcgbh");
       localStorage.setItem("dhdjdluytp","01245a4sd045");
       localStorage.setItem("un",isLogin?.data?.data?.user_id);
       localStorage.setItem("name",isLogin?.data?.data?.name);
       localStorage.setItem("ec",isLogin?.data?.data?.encryped_id);
       localStorage.setItem("country",isLogin?.data?.data?.country);
       localStorage.setItem("email",isLogin?.data?.data?.username);
       localStorage.setItem("ct",isLogin?.data?.data?.consent);
      
       if(queryData.current?.code ||  queryData.current?.goto || queryParams.get('code')){

        let stateObject = {};
        if (queryData.current?.code) {
          stateObject.code = queryData.current?.code;
        }
         if (queryParams.get('code')) {
          stateObject.code = queryParams.get('code');
        }
        if ( queryData.current?.goto) {
          stateObject.goto =  queryData.current?.goto;
        }
         
        navigate("/home", {
          state: stateObject,
        });
        //  navigate("/home", {
        //    state: { code: queryParams.get('code') },
        //  });
       }else{
         // loader("hide");
         navigate("/home");
       }
     }catch(err){
       console.log(err);
       loader("hide");
       navigate("/login");
     }
 }

 const checkAndCreateUser = async(email) => {
  try {
    // const email = 'aamir.saleem@shinedezign.com'; // Replace with the user's email
    const userId = await createUserIfNotExists(email);
    return userId;
  } catch (error) {
    console.error('Error in component:', error.message);
  }
}

const createUserIfNotExists = async (email) => {
  try {
    const userId = await checkUserExists(email);
    if (!userId) {
      // User does not exist, create a new user
      const userCredential = await createUserWithEmailAndPassword(auth, email, '12345678');
      const user = userCredential.user;
      // Save additional user data in Firestore
      await setDoc(doc(db, 'users', user.uid), {
        email: user.email,
        // Add any other user data you want to store
      });
      return user.uid;
    }
    // User already exists, return their ID
    return userId;
  } catch (error) {
    console.error('Error creating user:', error.message);
    throw error;
  }
};

const checkUserExists = async (email) => {
  try {
    const userCredential = await signInWithEmailAndPassword(auth, email, '12345678');
    const user = userCredential.user;
    return user.uid;
  } catch (error) {
    if (error.code === 'auth/user-not-found') {
      // User does not exist, return null
      return null;
    } else {
      // Handle other errors
      console.error('Error checking user existence:', error.message);
      throw error;
    }
  }
};
  const myFun = () => {
      loader("show");
     };
    return (
        <>
        </>
    )
}
export default Redirect
